.summaryCard {
  width: 100%;
  margin-bottom: 25px;
  padding: 10px;
  border: 3px solid #ccc;
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__description {
    font-size: 16px;
    text-align: justify;
    line-height: 1.5;
    padding: 15px 0;
  }
  &__studentDetails {
    color: #004e8d;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
  }
  &__metaData {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  p {
    margin: 0;
  }
}

.button {
  display: block;
  padding: 15px 25px;
  outline: none;
  color: #fff;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
  transition: ease-in-out 0.17s;
  letter-spacing: 2px;
  background-color: transparent;
  &--primary {
    border: 1px solid #004e8d;
    color: #004e8d;
    &:hover {
      color: #fff;
      background-color: #004e8d;
    }
  }
  &--secondary {
    border: 1px solid #98c000;
    color: #98c000;
    &:hover {
      color: #fff;
      background-color: #98c000;
    }
  }

  &--danger {
    border: 1px solid #bc0000;
    color: #bc0000;
    &:hover {
      color: #fff;
      background-color: #bc0000;
    }
  }

  &--disabled {
    cursor: not-allowed;
    color: #ccc;
    border: 1px solid #ccc;
    &:hover {
      color: #ccc;
      background-color: unset;
    }
  }

  &--noPadding {
    padding: 0;
  }
  &--noBorder {
    border: 0;
    &:hover {
      background-color: unset;
      color: inherit;
    }
  }
  &--marginRight15 {
    margin-right: 15px;
  }
  &--mobileFullWidth {
    @media screen and (max-width: 480px) {
      margin: 0 0 15px 0;
      width: 100%;
    }
  }
}

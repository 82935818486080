input[type="file"] {
  display: none;
}

.fileInput {
  &__handler {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  &__button {
    border: 1px solid #98c000;
    color: #98c000;
    padding: 10px 15px;
    cursor: pointer;
  }
  &__filename {
    font-weight: 700;
    margin-right: 15px;
  }
}

.wrapper {
  display: flex;
  width: 100%;
  min-height: 100%;
  height: auto;
  justify-content: flex-end;
}

main {
  flex-basis: calc(100% - 200px);
  max-width: calc(100% - 200px);
  background-image: url("../../assets/logo-ade.svg");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 20%;
  background-origin: content-box;
  padding: 15px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    justify-content: flex-start;
  }
  main {
    flex-basis: 100%;
    position: static;
    overflow: hidden;
    margin-top: 60px;
    max-width: 100%;
  }
}

.goals {
  &__controls {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

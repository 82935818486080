.textarea {
  display: flex;
  flex-direction: column;
  &__label {
    font-weight: 500;
    letter-spacing: 1.5px;
    margin-bottom: 5px;
    font-size: 14px;
    color: #5f5f5f;
  }
  &__field {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border-radius: 3px;
    border-color: #8d8d8d;
    border-style: solid;
    resize: none;
    &:focus,
    &:hover {
      border-color: #004e8d;
    }
  }
}

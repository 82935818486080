.navigation {
  &__handler {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  &__button {
    background: #004e8d;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 700;
    padding: 5px 7px;
    cursor: pointer;
  }
  &--withRightMargin {
    margin-right: 10px;
  }
  &--withLeftMargin {
    margin-left: 10px;
  }
}

.goal {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
  padding: 10px;
  border: 3px solid #ccc;

  &__editMode {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border: 0px;
  }
  &__wrapper {
    display: flex;
    max-width: 50%;
    margin-bottom: 32px;
    align-items: flex-end;
    @media screen and (max-width: 768px) {
      max-width: 100%;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  &__label {
    font-weight: 500;
    letter-spacing: 1.5px;
    margin-bottom: 5px;
    font-size: 14px;
    color: #5f5f5f;
    &--small {
      font-size: 8px;
    }
    &--gray {
      color: #b6b6b6;
    }
  }

  &__description {
    font-weight: 700;
    font-size: 18px;
  }
  &__result {
    margin: 0;
    font-size: 16px;
    white-space: pre-line;
  }
  &__no-result,
  &__author {
    margin: 0;
    font-size: 10px;
    color: #b6b6b6;
  }
  &__author {
    font-size: 8px;
  }
  &__controls {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    @media screen and (max-width: 768px) {
      margin-left: 10px;
      margin-top: 30px;
      flex-direction: row;
    }
  }
  &__manageControls {
    display: flex;
  }
  &__subControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__studentName {
    width: 100%;
    padding: 10px 0;
    background-color: #fff;
    margin: 0;
  }
  &--hidden {
    opacity: 0.5;
    border: 1px solid #ccc;
  }
}

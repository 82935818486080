.navigation {
  width: 200px;
  height: 100%;
  display: flex;
  border-right: 1px solid #ccc;
  background-color: #004e8d;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: initial;
    overflow: hidden;
    border-right: 0px;
    border-bottom: 3px solid #004e8d;
  }

  &__header {
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__logoHandler {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      justify-content: space-between;
    }
  }
  &__logo {
    max-width: 75%;
    width: 100%;
    height: auto;
    padding: 15px 0;
    @media screen and (max-width: 768px) {
      max-width: initial;
      width: 100px;
    }
  }

  &__linksHandler {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 80px);
    margin-top: 20px;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &__links {
    padding: 5px;
    margin-left: 10px;
    margin-right: 10px;
    height: 75%;
    overflow: auto;
    @media screen and (max-width: 768px) {
      overflow: auto;
      max-height: 200px;
      margin-bottom: 20px;
    }
  }

  &__link {
    cursor: pointer;
    display: block;
    margin-bottom: 25px;
    font-size: 20px;
    color: #fff;
    letter-spacing: 2px;
    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
      font-size: 15px;
    }
    &--highlight {
      color: #ffa600;
    }
  }
  &__mobileToggler {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  &__logout {
    background: none;
    font-size: 20px;
    color: #fff;
    border: 0px;
    text-align: left;
    margin-bottom: 25px;
    margin-left: 10px;
    cursor: pointer;
    letter-spacing: 2px;
  }
}

.yearsHandler {
  margin-bottom: 16px;
}

.yearsHeading {
  display: flex;
  align-items: center;
}
.trimesterHandler {
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 15px;
}

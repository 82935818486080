.news {
  &__single {
    background-color: #deefff;
    border: 2px solid #ccc;
    padding: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
    a {
      color: #004e8d;
    }
    & p {
      text-align: justify;
      word-wrap: break-word;
      margin: 5px 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__groupDetails {
    margin-top: 15px;
    font-size: 12px;

    strong {
      font-size: 12px;
    }
  }
  &__metaDetails {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;

    strong {
      font-size: 12px;
    }
  }
  &__removeButton {
    background: none;
    border: 0;
    outline: 0;
    color: #bc0000;
    cursor: pointer;
  }
  &__editButton {
    background: none;
    border: 0;
    outline: 0;
    color: green;
    cursor: pointer;
  }
  &__attachment {
    font-size: 0.8rem;
    background-color: transparent;
    border: 0;
    outline: 0;
    color: #004e8d;
    margin: 10px 0 0 0;
    padding: 0;
    cursor: pointer;
    display: block;

    span {
      margin-left: 5px;
    }
  }
  &__comment {
    background-color: #eeeeee;
    padding: 10px;
    margin-bottom: 10px;
  }
  &__comment-author {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.7rem;
  }
  &__comment-content {
    font-size: 1rem;
  }
  &__comment-date {
    font-size: 0.7rem;
    color: #5f5f5f;
  }
  &__comment-action-button {
    background-color: transparent;
    border: 0px;
    color: #004e8d;
    padding: 0;
    font-weight: 500;
    cursor: pointer;
    &:first-of-type {
      margin-right: 5px;
    }
  }
}

.ql-editor {
  min-height: 200px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}
.quill {
  margin-top: 15px;
  margin-bottom: 15px;
}

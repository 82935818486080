.profile {
  color: #004e8d;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__user {
    display: flex;
    flex-direction: column;
  }
  &__userName {
    font-weight: 700;
  }
  &__role {
    font-weight: 300;
    color: #585858;
    font-size: 12px;
  }
  &__logoutIcon {
    margin-left: 15px;
  }
}

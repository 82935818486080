html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

a {
  text-decoration: none;
  color: #004e8d;
  font-weight: 500;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 20px;
}

p,
strong,
li {
  line-height: 1.5;
  font-size: 18px;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

.active {
  color: green;
  font-weight: 700;
}

.iconWithMarginRight {
  margin-right: 8px;
}

.marginLeft8px {
  margin-left: 8px;
}

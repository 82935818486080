.logoHandler {
  display: flex;
  justify-content: center;
  width: 100%;
}
.logo {
  max-width: 75%;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

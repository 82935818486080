.authWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #004e8d;
}

.authContainer {
  max-width: 400px;
  width: 100%;
}

.errorMessage {
  border: 1px solid red;
  background-color: #ff6b6b;
  color: white;
  font-weight: 700;
  width: 100%;
  padding: 15px 5px;
  display: block;
  margin-bottom: 15px;
  text-align: center;
}

.controls {
  display: flex;
  align-items: center;

  &--toRight {
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  &__handler {
    display: flex;
  }
}
